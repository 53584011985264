
import { Routes } from '@angular/router';

import {
	ErpArticleEditFormComponent
} from '../../../../components';

export const ArticleRoutes: Routes = [
	{
		path: 'edit',
		component: ErpArticleEditFormComponent
	}
];
