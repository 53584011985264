
import { NgModule } from '@angular/core';

import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RatingModule } from 'primeng/rating';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';

import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { TreeDragDropService } from 'primeng/api';

@NgModule({
	exports: [
		OrderListModule,
		OverlayPanelModule,
		ButtonModule,
		InputTextModule,
		DialogModule,
		TabMenuModule,
		ToastModule,
		DataViewModule,
		CardModule,
		ToolbarModule,
		ConfirmDialogModule,
		RatingModule,
		FileUploadModule,
		CheckboxModule,
		VirtualScrollerModule,
		ChartModule,
		DropdownModule,
		InputTextareaModule,
		ToggleButtonModule,
		TreeModule
	],
	providers: [
		MessageService,
		ConfirmationService,
		TreeDragDropService
	]
})
export class PrimeNgModule { }
