import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import {
	LoginService,
	LoginResponseModel
} from '../../services/login';
import {
	AppService
} from '../../services/app';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss']
})
export class
	SignInPage
implements
	OnInit
{

	private Username : string = '';
	private Password : string = '';

	constructor(
		private loginService: LoginService,
		private appService: AppService,
		private router : Router
	)
	{
	}

	ngOnInit()
	{
	}

	SignIn()
	{
		this.loginService.Login(
			this.Username,
			this.Password
		).pipe(take(1))
		.subscribe(res => {
		},
		err => {
			this.Password = '';
			this.appService.ToastError("Anmeldung",err.message);
		},
		() => {
			this.Username = '';
			this.Password = '';
			this.router.navigate(['/wellcome']);
			this.appService.SignedIn();
			this.appService.ToastSuccess("Anmeldung","Erfolgreich angemeldet");
		});
	}
}
