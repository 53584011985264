import { Component, OnInit } from '@angular/core';

import {
	DrpBxRestModel,
	DrpBxRestService
} from '../../rest-api/drpbx';


@Component({
  selector: 'app-drpbx-page',
  templateUrl: './drpbx.page.html',
  styleUrls: ['./drpbx.page.scss']
})
export class
	DrpBxPage
implements
	OnInit
{
	constructor(
		private drpbxRestService: DrpBxRestService
	)
	{
	}

	ngOnInit()
	{
	}


	private UploadList : Array<File>;

	Upload() {
		const files: Array<File> = this.UploadList;
		//console.log(files);
		let drpbxs = new Array<DrpBxRestModel>();

		for(let i =0; i < files.length; i++){
			let f = files[i];
			let drpbx = new DrpBxRestModel();
			drpbx.Name = f.name;
			drpbx.Data = f;
			drpbxs.push(drpbx);
		}
		console.log(drpbxs);
		this.drpbxRestService.Put(drpbxs)
			.subscribe(_=>{
				console.log(_)
			})
	}

	AppendFile(evt: any) {
		this.UploadList = <Array<File>>evt.target.files;
	}
}
