
import { Routes } from '@angular/router';


import {
	ChpwdPage
} from '../../../pages';

export const UserRoutes: Routes = [
	{
		path: 'chpwd',
		component: ChpwdPage
	}
];
