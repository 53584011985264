
import { Routes } from '@angular/router';


import {
	CmsTecntPage
} from '../../../pages';

export const TecntRoutes: Routes = [
	{
		path: '',
		component: CmsTecntPage
	}
];
