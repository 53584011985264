import {
	Component,
	OnInit
} from '@angular/core';
import {
	AppService,
	ToastModel,
	ConfirmModel
} from '../services/app';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
implements OnInit
{
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  title = 'orga';
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	constructor(
		private appService: AppService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService
	){

	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	ngOnInit()
	{
		this.appService.ToastObserver.subscribe(
			toast => this.OnToast(toast));
		this.appService.ConfirmObserver.subscribe(
			confirm => this.OnConfirm(confirm));
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public OnConfirm(confirm:ConfirmModel)
	{
		this.confirmationService.confirm({
			message: confirm.Text,
			accept: () => {
				//console.log('confirmation: accepted',confirm.Subject);
				if(confirm.Subject)confirm.Subject.next(true);
			},
			reject: () => {
				//console.log('confirmation: rejected',confirm.Subject);
				if(confirm.Subject)confirm.Subject.next(false);
			}
		});
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	private OnToast(toast:ToastModel)
	{
		if(!toast) return;
		if(toast.Type === "success"){
			this.messageService.add({severity:'success',
				summary:toast.Title, detail:toast.Text});
		}else if(toast.Type === "info"){
			this.messageService.add({severity:'info',
				summary:toast.Title, detail:toast.Text});
		}else if(toast.Type === "error"){
			this.messageService.add({severity:'error',
				summary:toast.Title, detail:toast.Text});
		}
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
}
