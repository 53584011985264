
export class EventModel
{
	public Type: string = "";
	public Text: string = "";
}

export var EventTypes = {
	SignedIn: "signed-in",
}
