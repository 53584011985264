import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { TreeNode } from 'primeng/api';
import {
	TestEasyContentRestModel,
	TestEasyContentRestService
} from '../../../rest-api/cms/tecnt';
import {
	BlobRestModel,
	BlobRestService
} from '../../../rest-api/cms/blob';
import {
	AppService
} from '../../../services/app';

class Entry {
	public IsNew : boolean = false;
	public NeedStore : boolean = false;
	public IsDeleted : boolean = false;
	public Content : TestEasyContentRestModel;
	public Parent : Entry;
	public Node : TreeNode = {};
	public Category : EntryIndex;
	public Children = new EntryIndex();
	private mSortIndex : number;
	public get SortIndex() : number {
		return this.mSortIndex;
	}
	public set SortIndex(index:number) {
		if(this.mSortIndex == index) return;
		this.NeedStore = true;
		this.mSortIndex = index;
		this.UpdateNode();
	}
	public set PrivateSortIndex(index:number) {
		this.mSortIndex = index;
	}
	public get ID() : string{
		if(!this.Content) return "";
		return this.Content.ID;
	}
	public get PID() : string{
		if(!this.Content) return "";
		return this.Content.PID;
	}
	public set PID(pid : string) {
		if(!this.Content) return;
		this.Content.PID = pid;
	}
	public get Type() : string{
		if(!this.Content) return "";
		return this.Content.Type;
	}
	public get Name() : string{
		if(!this.Content) return "";
		return this.Content.Name;
	}
	public get CatID() : string{
		return this.Type;
	}
	public Unlink(){
		if(this.Category){
			this.Category.Remove(this);
			delete this.Category;
		}
		if(this.Parent){
			this.Parent.Remove(this);
			delete this.Parent;
		}
	}
	public get Properties(){
		let props : Entry[] = [];
		this.Children.ForEach(c => {
			if(c.Type == 'Prop') props.push(c);
		});
		return props;
	}
	public get ShowInTree(){
		if(this.Type == 'Prop') return false;
		return true;
	}
	public Get(id:string) : Entry {
		return this.Children.Get(id);
	}
	public Add(e:Entry){
		if(!e) return;
		//console.error('Add: ', e.ID, ' to: ', this.ID);
		e.SetParent(this);
		this.Children.Add(e);
		//if(e.ShowInTree){
		//	if(!this.Node.children) this.Node.children = [];
		//	let exists = this.Node.children.filter(c => c == e.Node);
		//	if(exists.length < 1) this.Node.children.push(e.Node);
		//}
	}
	public MoveChildTo(e:Entry,index:number){
		//console.log('MoveChildTo:',index);
		if(!e) return;
		if(index < 0) return;
		if(e.Parent != this) return;
		let lst = new Array<Entry>();
		this.Children.ForEach(c => {
			if(c == e) return;
			lst.push(c);
		});
		lst.sort((a,b)=>{
			return a.SortIndex - b.SortIndex;
		});
		if(index < lst.length){
			lst.splice(index,0,e);
		}else{
			lst.push(e);
		}
		var ridx = 0;
		lst.map(c => {
			//console.log('From:',c.SortIndex,'To:',ridx);
			c.SortIndex = ridx;
			++ridx;
		});
	}
	public UpdateChildTree() {
		if(this.ShowInTree){
			this.UpdateNode();
			let lst = new Array<Entry>();
			this.Children.ForEach(c => {
				lst.push(c);
			});
			lst.sort((a,b)=>{
				return a.SortIndex - b.SortIndex;
			});
			this.Node.children = [];
			lst.map(c => {
				if(c.ShowInTree){
					this.Node.children.push(c.Node);
				}
			});
		}
	}
	public UpdateChildTreeAll() {
		this.UpdateChildTree();
		this.Children.ForEach(c => {
			c.UpdateChildTreeAll();
		});
	}
	public Remove(e:Entry){
		if(!e) return;
		//console.error('Remove: ', e.ID, ' from: ', this.ID);
		this.Children.Remove(e);
		if(e.ShowInTree){
			if(!this.Node.children) this.Node.children = [];
			this.Node.children = this.Node.children.filter(c => c != e.Node);
		}
	}
	public ClearChildren() {
		this.Children.Clear();
	}
	public SetParent(e:Entry){
		if(this.Parent == e) return;
		if(e){
			if(this.PID != e.ID){
				this.NeedStore = true;
				this.PID = e.ID;
			}
		}else{
			this.NeedStore = true;
			this.PID = '';
		}
		if(this.Parent){
			this.Parent.Remove(this);
		}
		this.Parent = e;
	}
	public ForEachChild(callback){
		this.Children.ForEach(callback);
	}
	public UpdateNode()
	{
		(this.Node as any).xEntry = this;
		this.Node.label = this.Name;
		if(this.Type == "Prop"){
			this.Node.label += ':' + this.Content.Data.trim()
		}
		this.Node.label += ' '
			+ (this.IsNew ? '*' : '')
			+ (this.NeedStore ? '*' : '')
			+ (this.IsDeleted ? '-' : '')
			+ ' (' + this.Type + ')';
		this.Node.label += ' : ' + this.SortIndex;
		this.Node.data = this.Name;
		this.Node.expandedIcon = "fa fa-folder-open";
		this.Node.collapsedIcon = "fa fa-folder";
		if(!this.Node.children) this.Node.children = [];
	}
	public PrepareStore()
	{
		if(!this.Content) return;
		this.Content.Sort = this.SortIndex + '';
	}
}

class EntryIndex {
	public Index = new Map<string,Entry>();
	public Clear(){
		this.Index = new Map<string,Entry>();
	}
	public Add(e:Entry){
		if(!e) return;
		this.Index.set(e.ID,e);
	}
	public Get(id:string):Entry{
		if(!id) return null;
		return this.Index.get(id);
	}
	public Remove(e:Entry){
		if(!e) return;
		this.Index.delete(e.ID);
	}
	public ForEach(callback){
		this.Index.forEach(callback);
	}
	public get Count(){
		return this.Index.size;
	}
}

class EntryCategory {
	Categories : {[key:string]:EntryIndex} = {}
	public Clear(){
		this.Categories = {};
	}
	public Get(name:string):EntryIndex{
		var c = this.Categories[name];
		if(!c){
			c = this.Categories[name] = new EntryIndex();
		}
		return c;
	}
	public Set(name:string,mdl:Entry){
		this.Get(name).Index[mdl.ID] = mdl;
	}
}

@Component({
  selector: 'app-tecnt-page',
  templateUrl: './tecnt.page.html',
  styleUrls: ['./tecnt.page.scss']
})
export class
	CmsTecntPage
implements
	OnInit
{

	private contentDataTree : TreeNode[] = [];
	private selectedContent : TreeNode;
	private selectedEntry : Entry;
	private selectedTenc : TestEasyContentRestModel;
	private selctedType : string;

	private mainEntry = new Entry();
	private index = new EntryIndex();
	private categories = new EntryCategory();

	private blobs : BlobRestModel[];

	private tecntTypes = [
		{label:'Nothing',value:'Nothing'},
		{label:'Project',value:'Project'},
		{label:'Page',value:'Page'},
		{label:'Content',value:'Content'},
		{label:'Text',value:'Text'},
		{label:'Prototype',value:'Prototype'}
	];

	constructor(
		private tecntRstSrvc: TestEasyContentRestService,
		private blobRestService: BlobRestService,
		private appService: AppService
	)
	{
	}

	ngOnInit()
	{
		this.BuildTecnts();

		this.blobRestService.GetAll().pipe(take(1)).subscribe(
			data => {
				this.blobs = data;
			})
	}

	Debug()
	{
		//console.log(this.contentDataTree);
	}

	BlobURL(blob : BlobRestModel)
	{
		return this.blobRestService.GetBlobURL(blob.ID);
	}

	GetBlobMimeType(file : File) : string {
		if(!file) return "text/plain";
		let lname = file.name.toLowerCase();
		if(lname.indexOf('jpg') > 0 || lname.indexOf('jpeg') > 0){
			return "image/jpeg";
		}else if(lname.indexOf('png') > 0){
			return "image/png";
		}
		return "application/octstream";
	}

	private BlobUploadList : Array<File>;

	BlobsUpload() {
		const files: Array<File> = this.BlobUploadList;
		//console.log(files);
		let blobs = new Array<BlobRestModel>();

		for(let i =0; i < files.length; i++){
			let f = files[i];
			let blob = new BlobRestModel();
			blob.ID = this.CreateID();
			blob.Name = f.name;
			blob.Mime = this.GetBlobMimeType(f);
			blob.Data = f;
			blobs.push(blob);
		}
		console.log(blobs);
		this.blobRestService.Put(blobs)
			.subscribe(_=>{
				console.log(_)
			})
	}

	BlobAppendFile(evt: any) {
		this.BlobUploadList = <Array<File>>evt.target.files;
	}

	AddPropertyEntry(parent:Entry){
		if(!parent) return;
		this.appService.Confirm("Neue Eigenschaft hinzufügen?")
		.pipe(take(1)).subscribe(ok => {
			if(!ok) return;
			let prop = this.CreateNewEntry();
			prop.Content.Type = 'Prop';
			prop.Content.Name = '';
			prop.Content.Data = '';
			parent.Add(prop);
		});
	}

	RemovePropertyEntry(parent:Entry,propChild:Entry){
		if(!parent || !propChild) return;
		this.appService.Confirm("Eigenschaft entfernen?")
		.pipe(take(1)).subscribe(ok => {
			if(!ok) return;
			parent.Remove(propChild);
		});
	}

	eCnt : number = 0;
	CreateID() : string {
		return (new Date().getTime()) + '-' + ++this.eCnt;
	}
	CreateNewEntry()
	{
		let c = new TestEasyContentRestModel();
		c.ID = this.CreateID();
		c.PID = this.selectedTenc ? this.selectedTenc.ID : '';
		c.Type = 'text';
		let e = new Entry();
		e.IsNew = true;
		e.NeedStore = true;
		e.Content = c;
		return e;
	}
	AddNewEntry()
	{
		let e = this.CreateNewEntry();
		this.AppendEntry(e);
		if(e.Parent && e.Parent.Children){
			e.SortIndex = e.Parent.Children.Count
		}
		this.BuildTree();
		this.SelectEntry(e);
	}

	StoreEntry(e : Entry)
	{
		if(!e.Content){
			this.appService.ToastError("Speichern","Keine Datenelement vorhanden");
			return;
		}
		e.PrepareStore();
		this.tecntRstSrvc.Put([e.Content]).pipe(take(1)).subscribe(
			_ => {
			},
			err => {
				console.error(err);
				this.appService.ToastError("Speichern",err.message);
			},
			() => {
				e.IsNew = false;
				e.NeedStore = false;
				e.UpdateNode();
				this.appService.ToastSuccess("Speichern","Erfolgreich gespeichert");
			}
		);
	}

	StoreAllEntries()
	{
		let entries = new Array<Entry>();
		let mdls = new Array<TestEasyContentRestModel>();
		this.index.ForEach(e => {
			if(!e.Content) return;
			if(e.IsNew && e.IsDeleted){
				// ... do nothing
			}else if(e.NeedStore){
				e.PrepareStore();
				entries.push(e);
				mdls.push(e.Content);
			}
		});
		if(mdls.length < 1){
			this.appService.ToastSuccess("Speichern","Nichts zu speichern");
			return;
		}
		this.tecntRstSrvc.Put(mdls).pipe(take(1)).subscribe(
			_ => {
			},
			err => {
				console.error(err);
				this.appService.ToastError("Speichern",err.message);
			},
			() => {
				entries.map(e=>{
					e.IsNew = false;
					e.NeedStore = false;
					e.UpdateNode();
				});
				this.appService.ToastSuccess("Speichern","Erfolgreich gespeichert");
			}
		);
	}

	EntryChanged(e : Entry)
	{
		if(!e) return;
		e.NeedStore = true;
	}

	BuildTree()
	{
		this.index.ForEach(e=>{
			let pid = e.PID;
			if(pid && pid.length > 0){
				let p = this.index.Get(pid);
				if(p){
					p.Add(e);
				}
			}
			if(e.Parent) return;
			this.mainEntry.Add(e);
		});
		this.mainEntry.UpdateChildTreeAll();
		this.contentDataTree = this.mainEntry.Node.children;
	}

	AppendEntry(e : Entry)
	{
		if(!e) return;
		e.Unlink();
		this.index.Add(e);
		this.categories.Set(e.CatID,e);
		let parent = this.index.Get(e.PID);
		if(parent) parent.Add(e);
	}

	AppendTecnt(tecnt : TestEasyContentRestModel)
	{
		if(!tecnt) return;
		let e = new Entry();
		e.PrivateSortIndex = parseInt(tecnt.Sort);
		e.Content = tecnt;
		this.AppendEntry(e);
	}

	ReloadEntries()
	{
		this.appService.Confirm('Neu laden?').subscribe(
			ok => {
				//console.log('neu laden: ',ok);
				if(ok) this.BuildTecnts();
			});
	}

	GenerateWebsites()
	{
		this.appService.Confirm('Seite jetzt erzeugen?').subscribe(
		ok => { if(ok){
			this.tecntRstSrvc.GenPages().pipe(take(1)).subscribe(
				_ => {},
				err => {
					this.appService.ToastError(
						"Erzeuge Seite",
						err.message);
				},
				() => {
					this.appService.ToastSuccess(
						"Erzeuge Seite",
						"Erfolgreich generiert");
				}
			);
		}});
	}

	LoadTecnts()
	{
		return this.tecntRstSrvc.GetAll().pipe(take(1));
	}

	BuildTecnts()
	{
		this.LoadTecnts().subscribe(
			tecnts => {
				this.index.Clear();
				this.categories.Clear();
				tecnts.map(tecnt => this.AppendTecnt(tecnt));
			},
			err => {},
			() => {
				this.BuildTree();
				this.contentDataTreeExpandAll();
			}
		);
	}

	UpdateSelectedEntry()
	{
		if(!this.selectedEntry) return;
		this.selectedEntry.UpdateNode();
	}

	OnContentDataTreeDrop(evt)
	{
		//console.log(evt);
		var allowDrop : boolean = true;
		let dragNode = evt.dragNode;
		let dropNode = evt.dropNode;
		let index = evt.index;
		let dropIndex = evt.dropIndex;
		let dropToParent = !isNaN(dropIndex);
		let dragEntry = (dragNode as any).xEntry;
		let dropEntry = (dropNode as any).xEntry;
		var dropTo = dropEntry;
		var dropIdx = index;

		if(dropToParent){
			dropTo = dropEntry.Parent;
			dropIdx = dropIndex;
		}
		if(dropTo){
			//console.log('Add: ', dragEntry.ID, ' to: ', dropTo.ID, ' at: ', dropIdx);
			dropTo.Add(dragEntry);
			//dragEntry.SetParent(dropTo);
			dropTo.MoveChildTo(dragEntry,dropIdx);
		}else{
			//console.log('Make: ', dragEntry.ID, ' RootNode at: ', dropIdx);
			dragEntry.SetParent(null);
		}

		dragEntry.UpdateNode();

		if(allowDrop){
			evt.accept();
		}
	}

	OnContentSelected(evt){
		this.SelectContent(this.selectedContent);
	}

	SelectContent(n : TreeNode){
		if(n) this.SelectEntry((n as any).xEntry);
	}

	SelectEntry(e : Entry){
		if(e){
			this.selectedEntry = e;
			this.selectedTenc = e.Content;
			this.selctedType = e.Content.Type;
			if(this.selectedContent != e.Node){
				this.selectedContent = e.Node;
			}
		}else{
			this.selectedContent = null;
			this.selectedEntry = null;
			this.selectedTenc = null;
			this.selctedType = null;
		}
	}

	ContentWithCType = ['Content'];
	get ShowPropCType()
	{
		return this.ContentWithCType.includes(this.selctedType)
	}

	ContentWithValue = [];
	get ShowPropValue()
	{
		return this.ContentWithValue.includes(this.selctedType)
	}

	ContentWithTitle = ['Project','Page'];
	get ShowPropTitle()
	{
		return this.ContentWithTitle.includes(this.selctedType)
	}

	ContentWithData = ['Project','Page','Text','Image','Prop'];
	get ShowPropData()
	{
		return this.ContentWithData.includes(this.selctedType)
	}

	contentDataTreeExpandAll(){
		this.contentDataTree.forEach( node => {
			this.expandRecursive(node, true);
		} );
	}

	contentDataTreeCollapseAll(){
		this.contentDataTree.forEach( node => {
			this.expandRecursive(node, false);
		} );
	}

	expandRecursive(node:TreeNode, isExpand:boolean){
		node.expanded = isExpand;
		if(node.children){
			node.children.forEach( childNode => {
				this.expandRecursive(childNode, isExpand);
			});
		}
	}
}
