
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
	map,
	mergeMap
} from 'rxjs/operators';
import { ConfirmModel } from './confirm.model';
import { ToastModel } from './toast.model';
import { EventModel, EventTypes } from './event.model';


@Injectable({
  providedIn: 'root'
})
export class AppService
{
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	private UsernameSubject = new BehaviorSubject<string>(null);
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	private ConfirmSubject = new Subject<ConfirmModel>();
	private ToastSubject = new Subject<ToastModel>();
	private EventSubject = new Subject<EventModel>();

	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	constructor(
	)
	{
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public get UsernameObserver() : Observable<string>
	{
		return this.UsernameSubject.asObservable();
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public get Username() : string
	{
		return this.UsernameSubject.value || '';
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public SetUsername(username : string)
	{
		return this.UsernameSubject.next(username);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public get ConfirmObserver() : Observable<ConfirmModel>
	{
		return this.ConfirmSubject.asObservable();
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public Confirm(message:string) : Observable<boolean>
	{
		return of(true).pipe(
			take(1),
			mergeMap(_=>{
				let confirm = new ConfirmModel();
				confirm.Text = message;
				confirm.Subject = new Subject<boolean>();
				this.ConfirmSubject.next(confirm);
				return confirm.Subject.pipe(take(1));
			}),
			tap(ok=>{
					//console.log('confirmation: ',ok);
			}),
			take(1)
		);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public get ToastObserver() : Observable<ToastModel>
	{
		return this.ToastSubject.asObservable();
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public ToastMdl(t:ToastModel){
		this.ToastSubject.next(t);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public ToastTTT(_type:string,title:string,text:string){
		let mdl = new ToastModel();
		mdl.Type = _type;
		mdl.Title = title;
		mdl.Text = text;
		this.ToastMdl(mdl);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public ToastSuccess(title:string,text:string){
		this.ToastTTT("success",title,text);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public ToastInfo(title:string,text:string){
		this.ToastTTT("info",title,text);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public ToastError(title:string,text:string){
		this.ToastTTT("error",title,text);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public get EventObserver() : Observable<EventModel>
	{
		return this.EventSubject.asObservable();
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public get OnSignIn() : Observable<EventModel>
	{
		return this.EventObserver.pipe(
			filter(e => e.Type === EventTypes.SignedIn)
		);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public BroadcastEventMdl(e:EventModel){
		this.EventSubject.next(e);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public BroadcastEventType(et:string){
		let e = new EventModel();
		e.Type = et;
		this.BroadcastEventMdl(e);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public SignedIn(){
		this.BroadcastEventType(EventTypes.SignedIn);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
}
