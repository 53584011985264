
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { sha256 } from 'js-sha256';
import {
	map,
	mergeMap
} from 'rxjs/operators';

import {
	SaltRequestRestModel,
	SaltResponseRestModel,
	SaltRestService,
	LoginRequestRestModel,
	LoginResponseRestModel,
	LoginRestService
} from '../../rest-api/auth';

import {
	LoginResponseModel
} from './login-response.model';

import { RestApiService } from '../../rest-api/rest-api/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService
{

	constructor(
		private saltRestService : SaltRestService,
		private loginRestService : LoginRestService,
		private restService : RestApiService
	)
	{
	}

	public Login(
		username : string,
		password : string
	) : Observable<LoginResponseModel>
	{
		var lowerUsername = username;
		password = password + '';
		return of(true).pipe(
			mergeMap(_ => {
				lowerUsername = username.toLowerCase();
				let saltReq = new SaltRequestRestModel();
				saltReq.Username = lowerUsername;
				return this.saltRestService.RequestSalt(saltReq);
			}),
			mergeMap(saltResponse => {
				// First Hash
				var h1 = sha256.create();
				h1.update(lowerUsername+':'+password);
				let h1hex = h1.hex();
				// Second Hash
				var h2 = sha256.create();
				h2.update(h1hex.toLowerCase()+':'+saltResponse.Salt);
				let h2hex = h2.hex();
				//console.log(lowerUsername+':'+password);
				//console.log(h1hex);
				//console.log(h1hex.toLowerCase()+':'+saltResponse.Salt);
				//console.log(h2hex);
				// Set Data
				//console.log('HashA',h1hex);
				//console.log('HashB',h2hex);
				let loginReq = new LoginRequestRestModel();
				loginReq.Username = username;
				loginReq.Password = h2hex;
				return this.loginRestService.RequestLogin(loginReq);
			}),
			map(loginResponse => {
				let mdl = new LoginResponseModel();
				mdl.Username = loginResponse.Username;
				mdl.Token = loginResponse.Token;
				this.restService.SetUsername(loginResponse.Username || username);
				this.restService.SetAuthToken(loginResponse.Token);
				return mdl;
			})
		);
	}

	public IsLoggedInSync(
	) : boolean
	{
		return this.restService.HasAuthToken();
	}

	public IsLoggedIn(
	) : Observable<boolean>
	{
		return of(this.IsLoggedInSync());
	}

	public LogoutSync(
	) : boolean
	{
		this.restService.SetAuthToken(null);
		this.restService.SetUsername(null);
		return true;
	}

	public Logout(
	) : Observable<boolean>
	{
		return of(this.LogoutSync());
	}
}
