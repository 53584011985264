
import { Routes } from '@angular/router';

import {
	DrpBxPage
} from '../../pages';

export const DrpBxRoutes: Routes = [
	{
		path: '',
		component: DrpBxPage
	}
];
