
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	map
} from 'rxjs/operators';
import { RestApiService } from '../rest-api/rest-api.service';

import { SaltRequestRestModel } from './salt-request.rest-model'
import { SaltResponseRestModel } from './salt-response.rest-model'

@Injectable({
  providedIn: 'root'
})
export class SaltRestService
{
	constructor(
		private restService : RestApiService
	)
	{
	}

	public RequestSalt(
		request : SaltRequestRestModel
	) : Observable<SaltResponseRestModel>
	{
		return this.restService.Get('api/auth/salt')
		.pipe(
			map(res => {
				let mdl = new SaltResponseRestModel();
				mdl.Code = res['Code'];
				mdl.Salt = res['Salt'];
				return mdl;
			})
		);
	}
}
