import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
	MainRoutes
} from '../routes/main.routes';

@NgModule({
  imports: [RouterModule.forRoot(MainRoutes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
