
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	map
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RestApiService } from '../rest-api/rest-api.service';

import { DrpBxRestModel } from './drpbx.rest-model';

@Injectable({
  providedIn: 'root'
})
export class DrpBxRestService
{
	constructor(
		private restService : RestApiService
	)
	{
	}

	public GetAll(
	) : Observable<DrpBxRestModel[]>
	{
		return this.restService.Get('api/drpbx')
		.pipe(
			map(res => {
				let all : DrpBxRestModel[] = [];
				Object.assign(all,res);
				return all;
			})
		);
	}

	public GetFileURL(filename : string) : string
	{
		return environment.serverRootUrl
			+ 'api/drpbx?id=' + (filename || '').trim();
	}

	public Put(
		drpbxs : DrpBxRestModel[]
	) : Observable<boolean>
	{

		let formData = new FormData();
		for(let i = 0; i < drpbxs.length; i++){
			let b = drpbxs[i];
			formData.append(b.Name, b.Data);
		}
		return this.restService.Put('api/drpbx',formData,{
			'text':true
		})
		.pipe(map(_ => true));
	}
}
