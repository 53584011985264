
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	map
} from 'rxjs/operators';
import { RestApiService } from '../rest-api/rest-api.service';

import { ChpwdRequestRestModel } from './chpwd-request.rest-model';
import { ChpwdResponseRestModel } from './chpwd-response.rest-model';

@Injectable({
  providedIn: 'root'
})
export class ChpwdRestService
{
	constructor(
		private restService : RestApiService
	)
	{
	}

	public RequestChangePassword(
		request : ChpwdRequestRestModel
	) : Observable<ChpwdResponseRestModel>
	{
		return this.restService.Post('api/auth/chpwd',request)
		.pipe(
			map(res => {
				let mdl = new ChpwdResponseRestModel();
				mdl.Code = res['Code'];
				mdl.Message = res['Message'];
				return mdl;
			})
		);
	}
}
