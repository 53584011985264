
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	map
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../../rest-api/rest-api.service';

import { BlobRestModel } from './blob.rest-model';

@Injectable({
  providedIn: 'root'
})
export class BlobRestService
{
	constructor(
		private restService : RestApiService
	)
	{
	}

	public GetAll(
	) : Observable<BlobRestModel[]>
	{
		return this.restService.Get('api/cms/blob')
		.pipe(
			map(res => {
				let all : BlobRestModel[] = [];
				Object.assign(all,res);
				return all;
			})
		);
	}

	public GetBlobURL(blobID : string) : string
	{
		return environment.serverRootUrl
			+ 'api/cms/blob?id=' + (blobID || '').trim();
	}

	public Put(
		blobs : BlobRestModel[]
	) : Observable<boolean>
	{

		let formData = new FormData();
		let blobCopy = JSON.parse(JSON.stringify(blobs));
		blobCopy.map(b => {
			delete b.Data;
		})
		formData.append("jsn", JSON.stringify(blobCopy));

		for(let i =0; i < blobs.length; i++){
			let b = blobs[i];
			formData.append(b.ID, b.Data);
		}
		return this.restService.Put('api/cms/blob',formData,{
			'text':true
		})
		.pipe(map(_ => true));
	}
}
