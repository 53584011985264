import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signed-out-page',
  templateUrl: './signed-out.page.html',
  styleUrls: ['./signed-out.page.scss']
})
export class
	SignedOutPage
implements
	OnInit
{
	constructor()
	{
	}

	ngOnInit()
	{
	}
}
