
import { Routes } from '@angular/router';

import {
	TecntRoutes
} from './tecnt/tecnt.routes';


export const CmsRoutes: Routes = [
	{
		path: 'tecnt',
		children: TecntRoutes
	}
];
