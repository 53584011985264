
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { sha256 } from 'js-sha256';
import {
	map,
	mergeMap
} from 'rxjs/operators';

import {
	SaltRequestRestModel,
	SaltResponseRestModel,
	SaltRestService,
	ChpwdRequestRestModel,
	ChpwdResponseRestModel,
	ChpwdRestService
} from '../../rest-api/auth';

import { RestApiService } from '../../rest-api/rest-api/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class ChpwdService
{

	constructor(
		private saltRestService : SaltRestService,
		private chpwdRestService : ChpwdRestService,
		private restService : RestApiService
	)
	{
	}

	public ChangePassword(
		oldPassword : string,
		newPassword : string
	) : Observable<ChpwdResponseRestModel>
	{
		var username = this.restService.GetUsername();
		var lowerUsername = (username || '').toLowerCase();
		oldPassword = oldPassword + '';
		return of(true).pipe(
			mergeMap(_ => {
				let saltReq = new SaltRequestRestModel();
				saltReq.Username = lowerUsername;
				return this.saltRestService.RequestSalt(saltReq);
			}),
			mergeMap(saltResponse => {
				// First Hash
				var h1 = sha256.create();
				h1.update(lowerUsername+':'+oldPassword);
				let h1hex = h1.hex();
				// Second Hash
				var h2 = sha256.create();
				h2.update(h1hex.toLowerCase()+':'+saltResponse.Salt);
				let h2hex = h2.hex();
				//console.log(lowerUsername+':'+oldPassword);
				//console.log(h1hex);
				//console.log(h1hex.toLowerCase()+':'+saltResponse.Salt);
				//console.log(h2hex);
				// Set Data
				//console.log('HashA',h1hex);
				//console.log('HashB',h2hex);
				let chpwdReq = new ChpwdRequestRestModel();
				chpwdReq.OldPassword = h2hex;
				chpwdReq.NewPassword = newPassword;
				return this.chpwdRestService.RequestChangePassword(chpwdReq);
			})
		);
	}
}
