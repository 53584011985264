import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-fixed-center',
  templateUrl: './layout-fixed-center.component.html',
  styleUrls: ['./layout-fixed-center.component.scss']
})
export class
	LayoutFixedCenterComponent
implements
	OnInit
{
	constructor()
	{
	}

	ngOnInit()
	{
	}
}
