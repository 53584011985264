
import { Routes } from '@angular/router';

import {
	UserRoutes
} from './user/user.routes';

export const AppRoutes: Routes = [
	{
		path: 'user',
		children: UserRoutes
	}
];
