import { Injectable } from '@angular/core';
import {
	HttpClient,
	HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
	AppService
} from '../../services/app';

@Injectable({
  providedIn: 'root'
})
export class RestApiService
{
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	private UsernameKey : string = 'RespAPIUsername';
	private Username : string;
	private AuthTokenKey : string = 'RespAPIAuthToken';
	private AuthToken : string;
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	constructor(
		private httpClient : HttpClient,
		private appService : AppService
	)
	{
		this.AuthToken = sessionStorage.getItem(this.AuthTokenKey);
		if(this.AuthToken && this.AuthToken.trim().length < 5){
			this.AuthToken = null;
		}
		this.Username = sessionStorage.getItem(this.UsernameKey);
		if(this.Username && this.Username.trim().length < 1){
			this.Username = null;
		}
		this.appService.SetUsername(this.Username);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public SetUsername(username:string)
	{
		this.Username = username;
		if(username){
			sessionStorage.setItem(this.UsernameKey,username);
		}else{
			sessionStorage.removeItem(this.UsernameKey);
		}
		this.appService.SetUsername(username);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public GetUsername() : string
	{
		return this.Username || '';
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public SetAuthToken(token:string)
	{
		console.log('Set-AuthToken',token);
		this.AuthToken = token;
		if(token){
			sessionStorage.setItem(this.AuthTokenKey,token);
		}else{
			sessionStorage.removeItem(this.AuthTokenKey);
		}
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public HasAuthToken() : boolean
	{
		return this.AuthToken !== null;
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public GetAuthToken() : string
	{
		return this.AuthToken || '';
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	private BuildHeader(httpOptions:any)
	{
		httpOptions.headers = new HttpHeaders({
			//'Content-Type':  'application/json',
			'Authorization': this.AuthToken || 'my-auth-token'
		});
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	private ParseOptions(httpOptions:any,options:any)
	{
		//console.log('options',options);
		if(!options) return;
		let optKeys = Object.keys(options);
		for(var oIdx in optKeys){
			let oKey = optKeys[oIdx];
			let oVal = options[oKey];
			//console.log('key',oKey,'val',options[oKey]);
			if(oKey == 'responseType'){
				httpOptions['responseType'] = oVal;
			}else if(oKey == 'text' && oVal){
				httpOptions['responseType'] = 'text';
			}else if(oKey == 'rct'){
				httpOptions.headers = httpOptions.headers.Set(
					'Content-Type', oVal);
			}
		}
		//console.log(httpOptions.headers);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public Get(url : string, options? : any) : Observable<any>
	{
		let reqUrl = environment.serverRootUrl + url;
		let httpOptions = {};
		this.BuildHeader(httpOptions);
		this.ParseOptions(httpOptions,options);
		return this.httpClient.get(reqUrl,httpOptions);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public Post(url : string, data : any, options? : any) : Observable<any>
	{
		let reqUrl = environment.serverRootUrl + url;
		let httpOptions = {};
		this.BuildHeader(httpOptions);
		this.ParseOptions(httpOptions,options);
		return this.httpClient.post(reqUrl,data,httpOptions)
		.pipe(
			map(res => {
				//console.log(res);
				let code = (parseInt((res['code'] || 0) + '')) || 0;
				//console.log('code:',code);
				if(code > 0 || code < 0){
					throw new Error(res['emsg'] || 'ErrorNr:' + code);
				}
				return res;
			})
		);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
	public Put(url : string, data : any, options? : any) : Observable<any>
	{
		let reqUrl = environment.serverRootUrl + url;
		let httpOptions = {};
		this.BuildHeader(httpOptions);
		this.ParseOptions(httpOptions,options);
		return this.httpClient.put(reqUrl,data,httpOptions)
		.pipe(
			map(res => {
				//console.log(res);
				let code = (parseInt((res['code'] || 0) + '')) || 0;
				//console.log('code:',code);
				if(code > 0 || code < 0){
					throw new Error(res['emsg'] || 'ErrorNr:' + code);
				}
				return res;
			})
		);
	}
	// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
}
