import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import {
	ChpwdService
} from '../../../services/chpwd';
import {
	AppService
} from '../../../services/app';

@Component({
  selector: 'app-chpwd-page',
  templateUrl: './chpwd.page.html',
  styleUrls: ['./chpwd.page.scss']
})
export class
	ChpwdPage
implements
	OnInit
{

	private OldPassword : string;
	private NewPassword : string;

	constructor(
		private chpwdService: ChpwdService,
		private appService: AppService
	)
	{
	}

	ngOnInit()
	{
	}

	ChangePassword()
	{
		this.chpwdService.ChangePassword(
			this.OldPassword,
			this.NewPassword
		).pipe(take(1))
		.subscribe(res => {
		},
		err => {
			this.OldPassword = '';
			this.NewPassword = '';
			this.appService.ToastError("Passwort ändern",err.message);
		},
		() => {
			this.OldPassword = '';
			this.NewPassword = '';
			this.appService.ToastSuccess(
				"Passwort ändern",
				"Passwort wurde erfolgreich gändert");
		});
	}

	get Username()
	{
		return this.appService.Username;
	}
}
