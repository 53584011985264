
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	map
} from 'rxjs/operators';
import { RestApiService } from '../rest-api/rest-api.service';

import { LoginRequestRestModel } from './login-request.rest-model';
import { LoginResponseRestModel } from './login-response.rest-model';

@Injectable({
  providedIn: 'root'
})
export class LoginRestService
{
	constructor(
		private restService : RestApiService
	)
	{
	}

	public RequestLogin(
		request : LoginRequestRestModel
	) : Observable<LoginResponseRestModel>
	{
		return this.restService.Post('api/auth/login',request)
		.pipe(
			map(res => {
				let mdl = new LoginResponseRestModel();
				mdl.Code = res['Code'];
				mdl.Message = res['Message'];
				mdl.Username = res['Username'];
				mdl.Token = res['Token'];
				if(mdl.Code === 200 && mdl.Token){
					this.restService.SetAuthToken(mdl.Token);
				}else{
					this.restService.SetAuthToken(null);
					mdl.Message = 'failed';
				}
				return mdl;
			})
		);
	}
}
