
import { Routes } from '@angular/router';

import {
	LayoutComponent,
	LayoutFixedCenterComponent,
	ErpArticleEditFormComponent
} from '../components';

import {
	WellcomePage,
 	AboutPage,
	SignInPage,
	SignedOutPage
} from '../pages';

import { AppRoutes } from './app/app.routes';
import { CmsRoutes } from './cms/cms.routes';
import { ErpRoutes } from './erp/erp.routes';
import { DrpBxRoutes } from './drpbx/drpbx.routes';

export const MainRoutes: Routes = [
	{
		path: '',
		redirectTo: 'wellcome',
		pathMatch: 'full'
	},
	{
		path: 'wellcome',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: WellcomePage
			}
		]
	},
	{
		path: 'about',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: AboutPage
			}
		]
	},
	{
		path: 'sign-in',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: SignInPage
			}
		]
	},
	{
		path: 'signed-out',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: SignedOutPage
			}
		]
	},
	{
		path: 'orga',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: ErpArticleEditFormComponent
			},
			{
				path: 'app',
				children: AppRoutes
			},
			{
				path: 'cms',
				children: CmsRoutes
			},
			{
				path: 'erp',
				children: ErpRoutes
			},
			{
				path: 'drpbx',
				children: DrpBxRoutes
			}
		]
	}
];
