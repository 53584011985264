import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import {
	LoginService,
	LoginResponseModel
} from '../../services/login';
import {
	AppService
} from '../../services/app';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class
	LayoutComponent
implements
	OnInit
{
	LoggedIn : boolean = false;
	Username : string = '';
	Password : string = '';

	signInSub : any;

	constructor(
		private loginService: LoginService,
		private appService: AppService,
		private router : Router
	)
	{
	}

	ngOnInit()
	{
		this.LoggedIn = this.loginService.IsLoggedInSync();
		this.signInSub = this.appService.OnSignIn.subscribe(_ => {
			this.LoggedIn = this.loginService.IsLoggedInSync();
		});
	}

	ngOnDestroy()
	{
		this.signInSub.unsubscribe();
	}

	Login()
	{
		this.loginService.Login(
			this.Username,
			this.Password
		).pipe(take(1))
		.subscribe(res => {
		},
		err => {
			this.Password = '';
			this.LoggedIn = false;
			this.router.navigate(['/sign-in']);
			this.appService.ToastError("Anmeldung",err.message);
		},
		() => {
			this.Username = '';
			this.Password = '';
			this.LoggedIn = true;
			this.appService.ToastSuccess("Anmeldung","Erfolgreich angemeldet");
		});
	}

	Logout()
	{
		if(this.loginService.LogoutSync()){
			this.Username = '';
			this.Password = '';
			this.LoggedIn = false;
			this.router.navigate(['/signed-out']);
			this.appService.ToastSuccess("Abmeldung","Erfolgreich abgemeldet");
		}else{
			this.appService.ToastError("Abmeldung","Uuuupps...");
		}
	}
}
