import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { PrimeNgModule }    from '../app/prime-ng.module';
import { NgModule } from '@angular/core';

import {
	WellcomePage,
 	AboutPage,
	SignInPage,
	SignedOutPage,
	ChpwdPage,
	CmsTecntPage,
	DrpBxPage
} from './';

@NgModule({
	imports:[
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		RouterModule,
		PrimeNgModule
	],
	declarations: [
		WellcomePage,
		AboutPage,
		SignInPage,
		SignedOutPage,
		ChpwdPage,
		CmsTecntPage,
		DrpBxPage
	]
})
export class PagesModule { }
