import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-erp-article-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class
	ErpArticleEditFormComponent
implements
	OnInit
{

	constructor()
	{
	}

	ngOnInit()
	{
	}
}
