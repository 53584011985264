
import { Routes } from '@angular/router';

import {
	ArticleRoutes
} from './article/article.routes';

export const BaseDataRoutes: Routes = [
	{
		path: 'article',
		children: ArticleRoutes
	}
];
