
export * from './wellcome/wellcome.page';
export * from './about/about.page';
export * from './sign-in/sign-in.page';
export * from './signed-out/signed-out.page';

export * from './user/chpwd/chpwd.page';

export * from './cms/tecnt/tecnt.page';

export * from './drpbx/drpbx.page';
