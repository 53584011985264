import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import {
	LoginService,
	LoginResponseModel
} from '../../services/login';

@Component({
  selector: 'app-tiny-login',
  templateUrl: './tiny-login.component.html',
  styleUrls: ['./tiny-login.component.scss']
})
export class
	TinyLoginComponent
implements
	OnInit
{
	Username : string = '';
	Password : string = '';

	constructor(
		private loginService : LoginService
	)
	{
	}

	ngOnInit()
	{
	}

	IsLoggedIn() : boolean
	{
		return this.loginService.IsLoggedInSync();
	}

	Login()
	{
		this.loginService.Login(
			this.Username,
			this.Password
		).pipe(take(1))
		.subscribe(res => {
		},
		err => {
			this.Password = '';
		},
		() => {
			this.Username = '';
			this.Password = '';
		});
	}

	Logout()
	{
		this.loginService.LogoutSync();
		this.Username = '';
		this.Password = '';
	}
}
