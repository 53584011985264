
export class TestEasyContentRestModel
{
	public ID : string;
	public PID : string;
	public Type : string;
	public Name : string;
	public Title : string;
	public Data : string;
	public Sort : string;
}
