
import { RestApiResponseModel }
from '../rest-api/rest-api-response.model';

export class LoginResponseRestModel
extends RestApiResponseModel
{
	public Username : string;
	public Token : string;
}
