
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	map
} from 'rxjs/operators';
import { RestApiService } from '../../rest-api/rest-api.service';

import { TestEasyContentRestModel } from './tecnt.rest-model';

@Injectable({
  providedIn: 'root'
})
export class TestEasyContentRestService
{
	constructor(
		private restService : RestApiService
	)
	{
	}

	public GetAll(
	) : Observable<TestEasyContentRestModel[]>
	{
		return this.restService.Get('api/cms/tecnt')
		.pipe(
			map(res => {
				let all : TestEasyContentRestModel[] = [];
				Object.assign(all,res);
				return all;
			})
		);
	}

	public Put(
		mdls : TestEasyContentRestModel[]
	) : Observable<boolean>
	{
		return this.restService.Put('api/cms/tecnt',mdls,{'text':true})
		.pipe(map(_ => true));
	}

	public GenPages(
	) : Observable<boolean>
	{
		return this.restService.Get('api/cms/tecnt/gen',{'text':true})
		.pipe(map(_ => true));
	}
}
