
import { Routes } from '@angular/router';

import {
	BaseDataRoutes
} from './base-data/base-data.routes';

export const ErpRoutes: Routes = [
	{
		path: 'base-data',
		children: BaseDataRoutes
	}
];
